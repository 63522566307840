.container {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  margin: 0 auto;
  gap: 32px;
}

.title span {
  color: #ff0416;
}

.cardsContainer {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  color: #ffefef;
  flex: 1 0 0;
  border-radius: 12px;
  border: 1px solid #ffefef;
  padding: 16px 8px;
  min-width: 300px;
}

.card_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.card_title {
  font-size: 1.25rem;
  margin: 0;
  text-align: center;
}

.card_text {
  font-size: 0.875rem;
  margin: 0;
  text-align: center;
}

.card_text span {
  color: #ff0416;
  font-weight: 600;
}

@media (max-width: 900px) {
  .container {
    margin: 0 auto;
    max-width: 430px;
  }
}

@media (max-width: 500px) {
  .title,
  .cardsContainer {
    margin: 0 16px;
  }

  .card {
    min-width: 100%;
  }

  .cart_title br {
    display: none;
  }
}

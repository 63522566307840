.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
  max-width: 430px;
  margin: 0 auto;
  padding: 0 22px;
}

.title {
  font-size: 15px;
  font-weight: 600;
  color: #1F191F;
}

.subtitle {
  font-size: 15px;
  color: #1F191F;
}

.link {
  background-color: #0CC665;
  border: none;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  padding: 5px 20px;
  width: calc(100% - 140px);
  margin: 15px auto 40px;
  text-decoration: none;
  text-align: center;
}

.link:hover {
  background-color: #0AB55A;
}

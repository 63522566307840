.cookiesPopup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  background: #f4efe9;
  z-index: 40;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
}

.cookiesPopupText {
  margin-bottom: 20px;
  font-size: 16px;
  color: #1b1c1d;
  text-align: center;
  max-width: 1200px;
}

.cookiesPopupButtons {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.cookiesPopupButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 8px;
  border: 0;
  font-size: 16px;
  background-color: #1b1c1d;
  color: #f4efe9;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.cookiesPopupButton:last-child {
  background-color: #f4efe9;
  color: #1b1c1d;
  border: 1px solid #1b1c1d;
}

.cookiesPopupButton:hover {
  background-color: #1b1c1ddd;
  color: #f4efe9;
}

.cookiesPopupButton:last-child:hover {
  background-color: #f4efe9;
  text-decoration: underline;
  color: #1b1c1d;
}

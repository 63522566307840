.darkBG {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.modal {
  width: 580px;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 10px;
}

.heading {
  margin: 0;
  color: #1b1c1d;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin-right: 10px;
}

.modalContent {
  padding: 10px;
  font-size: 14px;
  color: #1b1c1d;
  max-height: 80vh;
  overflow-y: auto;
}

.modalContent p {
  padding: 0;
  margin-bottom: 12px;
}

.modalContent ul {
  padding-left: 20px;
}

.modalContent li {
  /* margin-bottom: 12px; */
  position: relative;
}

.modalContent li:last-child {
  margin-bottom: 12px;
}

.modalContent li:before {
  content: '';
  background: #1b1c1d;
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-left: -16px;
  position: absolute;
  margin-top: 6px;
}

.modalContent a {
  color: rgb(0, 0, 238);
  text-decoration: underline;
}

.modalContent h1,
.modalContent h2,
.modalContent h3 {
  font-size: 1rem;
  margin-top: 12px;
}

.modalContent p:last-child {
  margin-top: 12px;
}

.closeBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #1b1c1d;
  background: white;
  transition: all 0.25s ease;
  align-self: flex-end;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

@media (max-width: 600px) {
  .modal {
    width: calc(100vw - 40px);
  }
}

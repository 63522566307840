* {
  box-sizing: border-box;
  font-family: 'Montserrat', serif;
}

body {
  margin: 0;
  background: #f4efe9;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
}

.App {
  position: relative;
}

.App.no-cookies::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
}

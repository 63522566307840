.form_group {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.label {
  font-size: 12px;
  font-weight: 600;
  color: #1b1c1d;
}

.input {
  height: 30px;
  border-radius: 17px;
  border: 1px solid #a8a8a8;
  padding: 0 20px;
  color: #1b1c1d;
}

select.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7 7l5 5 5-5H7z" fill="%231B1C1D"/></svg>');
  background-repeat: no-repeat;
  background-position: right 15px top 7px;
  background-size: 20px;
}

.input:placeholder {
  color: #a8a8a8;
}

.error_message {
  font-size: 12px;
  color: #ff0000;
}

.label_disabled {
  color: #c7c6c8;
}

.input:disabled {
  border: 1px solid #c7c6c8;
  background-color: #fff;
  color: #c7c6c8;
  cursor: not-allowed;
}

.input:disabled::placeholder {
  color: #c7c6c8;
}

.form_group > div input {
  width: 10px;
  height: 10px;
}

.form_group > div label {
  font-size: 12px;
}

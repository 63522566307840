.background {
  background-color: #f4efe9;
  padding: 110px 22px 40px;
  position: relative;
}

.container {
  background-color: #fff;
  max-width: calc(430px - (22px * 2));
  border: 1px solid #e6e6e6;
  border-radius: 12px;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  padding: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form_paragraph {
  font-size: 12px;
  font-weight: 600;
  color: #1b1c1d;
  margin-bottom: 10px;
}

.form_button {
  background-color: #0cc665;
  border: none;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  padding: 5px 20px;
  width: calc(100% - 100px);
  margin: 10px auto;
}

.form_button:hover {
  background-color: #0ab55a;
}

.form_button:disabled {
  background-color: #e6e6e6;
  color: #b3b3b3;
  cursor: not-allowed;
}

.term {
  display: flex;
  align-items: start;
  gap: 10px;
  padding: 10px 0;
}

.term_text {
  font-size: 12px;
  font-weight: 600;
  color: #1b1c1d;
  margin-bottom: 10px;
  display: inline-block;
}

.term_text span,
.term_text a {
  color: rgb(0, 0, 238);
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
}

.error_message {
  color: #ff0000;
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;
}
@media (min-width: 900px) {
  .container {
    max-width: 920px;
  }

  .form_button {
    width: 220px;
  }
}

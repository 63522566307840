.topBackground {
  background-image: url('../../../assets/top-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 44px;
}

.callToActionSection {
  padding-top: 14px;
}

.callToActionContainer {
  display: flex;
  max-width: 430px;
  margin: 0 auto;
  background-image: url('../../../assets/coca-girl.png');
  background-repeat: no-repeat;
  background-position: 160px 0;
  background-size: 370px;
}

.logosContainer {
  display: flex;
  align-items: center;
}

.cocaLogo img {
  max-height: 59px;
}

.taqeLogo img {
  max-height: 29px;
}

.callToActionInfos {
  display: flex;
  flex-direction: column;
  padding-left: 22px;
  gap: 20px;
  padding-bottom: 43px;
}

.callToActionInfos h2 {
  font-size: 20px;
  font-weight: 600;
  color: #1b1c1d;
}

.callToActionInfos a {
  font-size: 22px;
  font-weight: 600;
  color: #f4efe9;
  text-decoration: none;
  background-color: #1c1c1a;
  padding: 8px 21px;
  width: fit-content;
}

.callToActionInfos a:hover {
  transform: translate(1px, 1px);
}

.aboutSection {
  background-color: #ff0416;
  border-radius: 20px 20px 0 0;
}

.aboutSection h1 {
  font-size: 25px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  padding: 18px 0;
}

.aboutGrayInfos {
  position: relative;
  background-color: #1f191f;
  padding: 50px 0 0 0;
  border-radius: 20px 20px 0 0;
}

.title_container {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  margin: 0 auto 60px;
  gap: 16px;
  padding: 0 12px;
}

.title {
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
}

.subtitle {
  font-size: 1.25rem;
  font-weight: 400;
  color: #fff;
}

.title span {
  color: #ff0416;
}

.videoInfosContainer {
  background-color: #f4efe9;
  border-radius: 20px 20px 0 0;
  margin-top: 50px;
}

.videoInfosContent {
  display: flex;
  flex-direction: column;
  height: 850px;
  max-width: 430px;
  margin: 0 auto;
  padding: 22px 0;
}

.iconInfoBoxVideo {
  color: #1b1c1d;
  padding: 0 22px;
}

.iconInfoBoxVideo img {
  max-height: 34px;
  margin-bottom: 10px;
}

.iconInfoBoxVideo span {
  font-weight: 600;
}

img.cocaTeam {
  width: 100%;
  /* crop image */
  object-fit: cover;
}

.subscribeButton {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  background-color: #11a3d9;
  /* height: 160px; */
  width: 100%;
  position: absolute;
  bottom: -70px;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0)
    ),
    url('../../../assets/subscribe-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
  padding: 32px 0;
}

.subscribeButton > a {
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
  color: #f4efe9;
  padding: 18px 48px;
  background: #1f191f;
  border-radius: 9px;
}

.subscribeButton > a:hover {
  transform: translate(1px, 1px);
}

.subscribeText {
  font-size: 1.375rem;
  text-align: center;
  font-weight: 600;
  color: #fff;
  max-width: 900px;
  padding: 0 16px;
}

@media (min-width: 900px) {
  .callToActionContainer {
    max-width: 920px;
    background-image: url('../../../assets/coca-team.png');
    background-position: 450px 0;
    background-size: 450px;
  }

  .callToActionInfos h2 {
    content: 'Coca-Cola';
  }

  .callToActionInfos > h2 > br:nth-child(2) {
    display: none;
  }

  .videoInfosContainer {
    min-height: 460px;
  }

  .videoInfosContent {
    display: flex;
    flex-direction: row;
    max-width: 920px;
    gap: 40px;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .subscribeText {
    font-size: 1.75rem;
  }

  .videoInfosContent img {
    max-height: 280px;
    width: 100%;
    border-radius: 20px;
  }

  .iconInfoBoxVideo img {
    max-height: 34px;
    max-width: 48px;
    margin-bottom: 10px;
  }

  img.cocaTeam {
    display: none;
  }
}

.background {
  width: 100%;
  height: 48px;
  display: block;
  background-image: url("../../../assets/success_page_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.success_page {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.success_message {
  display: flex;
  flex-direction: column;
  height: 60%;
  align-items: center;
  justify-content: space-between;
}

.success_message h1 {
  font-size: 25px;
  font-weight: 600;
  margin: 40px 0 20px;
  align-self: center;
}

.team_coca_image {
  padding: 0 22px;
  max-width: 430px;
  margin: 0 auto;
}

.message_whatsapp_container {
  display: flex;
  flex-direction: column;
  height: 40%;
  background-color: #1F191F;
  padding: 40px 0;
  border-radius: 20px 20px 0 0;
}

.whatsapp_img img {
  width: 40px;
  margin: 0 22px;
}

.whatsapp_text_box {
  max-width: 430px;
  margin: 0 auto;
}

.whatsapp_text {
  font-size: 20px;
  color: #F4EFE9;
  margin: 10px 22px;
}

.whatsapp_text span {
  font-weight: 600;
}

.logosContainer {
  display: flex;
  align-items: center;
}

.cocaLogo img {
  max-height: 59px;
}

.taqeLogo img {
  max-height: 29px;
}
